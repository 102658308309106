/*
Main rivalitas color: #35A8E0
gray color that matches: #868686
dark color: #062f4f
primary button color: #813772
*/

:root {
  font-family: Inter, Avenir, Helvetica, Arial, sans-serif;
}

#rivalitas-root {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
}

#rivalitas-top-menu {
  height: 32px;
  border-bottom: 2px solid black;
  display: flex;
  align-items: center;
  padding-left: 25px;
  padding-right: 25px;
  background-color: #062f4f;
  color: white;
}

#rivalitas-main-content {
  flex: 1;
  overflow: hidden;
}

#rivalitas-footer {
  height: 30px;
  border-top: 1px solid #868686;
  background-color: #dedede;
  display: flex;
  align-items: center;
  padding-left: 25px;
  padding-right: 25px;
}

#rivalitas-selected-menu {
  background-color: #E7F2FF;
  display: flex;
  height: 30px;
  border-bottom: 1px solid #868686;
  padding-left: 25px;
  padding-right: 25px;
  align-items: center;
  font-size: 11px;
}
.rivalitas-selected-menu-item-container {
  margin-right: 5px;
}

/* Screen containers */
.screen-container {
  margin-top: 20px;
  margin-left: 20px;
  height: calc(100vh - 200px);
  width: 1200px;
  border-radius: 5px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
  overflow-y: auto;
}

.screen-container-content {
  padding: 20px;
}

.row {
  display: flex;
  /* justify-content: space-between; */
  margin-bottom: 10px;
}

.col12 {
  width: 1160px;
}

.col6 {
  width: 570px;
  margin-right: 20px;
}
.col6:last-child {
  margin-right: 0px !important;
}

.col3 {
  width: 275px;
  margin-right: 20px;
}
.col3:last-child {
  margin-right: 0px !important;
}

.col4 {
  width: 373px;
  margin-right: 20px;
}
.col4:last-child {
  margin-right: 0px !important;
}

/* end screen containers */

.r-float {
  text-align: right;
}

/* Table cell celections */

.table-row-selected {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border-top: 1px solid #062f4f;
  border-bottom: 1px solid #062f4f;
  background-color: #ffc973 !important;
}

.table-row-selected-start {
  border-left: 1px solid #062f4f;
}

.table-row-selected-end {
  border-right: 1px solid #062f4f;
}

.table-cell-selected {
  border: 2px solid #062f4f;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}