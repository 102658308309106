#rivalitas-job-menu {
  display: flex;
  height: 30px;
  border-bottom: 1px solid #868686;
  padding-left: 25px;
  padding-right: 25px;
  align-items: flex-end;
  font-size: 11px;

  background-color: #f3f3f3;
}

.rivalitas-job-menu-item {
  background-color: #B4EBF6;
  border-left: 1px solid #c5c8d1;
  border-top: 1px solid #c5c8d1;
  border-right: 1px solid #c5c8d1;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  height: 22px;

  /* Total width for calculations: 120px */
  width: 115px;
  margin-right: 5px;

  color: #062f4f;

  cursor: pointer;

  position: relative;
}

.rivalitas-job-menu-item-selected {
  background-color: white !important;
  cursor: default !important;
}

.rivalitas-job-menu-item-container {
  text-align: center;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  margin-left: 10px;
  margin-right: 10px;

  margin-top: 5px;
}

.rivalitas-job-menu-item-container-close {
  position: absolute;
  right: 7px;
  top: 1px;
  width: 6px;
  height: 6px;
  cursor: pointer;
  color: red;
}

.rivalitas-job-menu-item-container-close:hover {
  color: #ff858f;
} 