.r-btn {
  border-radius: 5px !important;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  font-weight: 500;
  justify-content: center;
  outline: none;
  text-align: center;
  text-decoration: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
}

/* Types */
.r-btn-primary {
  color: #000000;
  background: #E7F2FF;
  border: 1px solid #0079A4;
}

.r-btn-primary:disabled {
  cursor: default;
  background-color: rgba(0, 0, 0, 0.12);
  border-color: transparent;
  box-shadow: none;
  color: rgba(0, 0, 0, 0.46);
  border: 1px solid #989898;
}

.r-btn-primary-inverse {
  font-weight: bold;
  color: #0079A4;;
  background: transparent;
  border: none;
}

.r-btn-primary-inverse:disabled {
  cursor: default;
  color: #E7F2FF;
}

.r-btn-secondary {
  color: #ffffff;
  background: #8232a8;
  border: 1px solid #672885;
}

.r-btn-secondary:disabled {
  cursor: default;
  background-color: rgba(0, 0, 0, 0.12);
  border-color: transparent;
  box-shadow: none;
  color: rgba(0, 0, 0, 0.46);
  border: 1px solid #989898;
}

.r-btn-transparent {
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0.12);
  border: 1px solid #0079A4;
}

.r-btn-transparent:disabled {
  cursor: default;
  background-color: rgba(0, 0, 0, 0.12);
  border-color: transparent;
  box-shadow: none;
  color: rgba(255, 255, 255, 0.46);
  border: 1px solid #989898;
}

.r-btn-danger {
  color: #ffffff;
  background: #b51b10;
  border: 1px solid #82120a;
}

.r-btn-danger:disabled {
  cursor: default;
  background-color: rgba(0, 0, 0, 0.12);
  border-color: transparent;
  box-shadow: none;
  color: rgba(0, 0, 0, 0.46);
  border: 1px solid #989898;
}

/* Sizes */

.r-btn-regular {
  line-height: 10px;
  font-size: 12px;
  padding: 5px 12px 5px;
}

.r-btn-small {
  line-height: 9px;
  font-size: 9px;
  padding: 3px 8px 1px;
}

.r-btn-large {
  line-height: 15px;
  font-size: 14px;
  padding: 6px 12px 4px;
}
